import { ProductType } from '@/common/types'
import {
  AddToCartEventHandlerParams,
  AddToCartParams,
  AddToCartTrackingConfig,
  SimpleVariantAddToCartParams,
} from '@/providers/cart/cart.types'
import { obsoloteGetPrice } from '@/common/utils'
import { ProductListViewPlacement } from '@/common/services/tracking/google/gtm-types'

export const addSimpleProductToCart = async (
  { productType, productName, sku, ...rest }: SimpleVariantAddToCartParams,
  onAddToCart?: (
    params: AddToCartParams,
    trackingConfig?: AddToCartTrackingConfig | undefined,
  ) => Promise<void>,
  productTracking?: {
    prodlistPlacement: ProductListViewPlacement
    productIds: number[]
    productPosition: number
  },
) => {
  // single-variant configurable product
  const isConfigurableProduct = productType === ProductType.Configurable

  if (isConfigurableProduct) {
    await onAddToCart?.({
      ...rest,
      ...productTracking,
      productName: productName ?? '',
      sku: sku ?? '',
      quantity: 1,
    })
  }
}

export const addConfigProductToCart = async (
  { product, variant, count, addToCart }: AddToCartEventHandlerParams,
  {
    productIds,
    productPosition,
    prodlistPlacement,
    configId,
    simpleId,
  }: {
    productPosition?: number
    productIds?: number[]
    prodlistPlacement?: ProductListViewPlacement
    configId: number
    simpleId: number
  },
) => {
  const { finalPrice } = obsoloteGetPrice(variant?.product)

  await addToCart({
    productName: product?.name ?? '',
    parentSku: product?.sku ?? '',
    sku: variant?.product?.sku ?? '',
    quantity: count,
    brand: product?.manufacturer_info?.name ?? '',
    price: finalPrice?.value_excl_tax
      ? Math.round(finalPrice.value_excl_tax * 100) / 100
      : undefined,
    category: product?.breadcrumb_en ?? '',
    prodlistPlacement,
    productPosition,
    productIds,
    configId,
    simpleId,
  })
}
