import { ConfigurableProductVariantFragment, ProductStockStatus } from '@/api'
import { ConfigurableVariantsData } from '@/common/types/product-types'

export const getInitialConfigurableProductVariant = (
  configurableVariants: ConfigurableVariantsData | null | undefined,
): ConfigurableProductVariantFragment | undefined => {
  const productVariants = (configurableVariants?.filter(
    (variant) => variant?.product?.enabled,
  ) ?? []) as ConfigurableProductVariantFragment[]

  // Find and return bestselling product variant
  const bestseller = productVariants.find(
    (variant) =>
      variant?.is_bestseller &&
      variant.product?.stock_status === ProductStockStatus.InStock,
  )
  if (bestseller) {
    return bestseller
  }

  // Find and return first in stock variant
  const firstInStock = productVariants.find(
    (variant) => variant?.product?.stock_status === ProductStockStatus.InStock,
  )
  if (firstInStock) {
    return firstInStock
  }

  // Find and return first enabled variant
  const firstEnabled = productVariants[0]
  if (firstEnabled) {
    return firstEnabled
  }

  // Find and return first existing variant
  return configurableVariants?.[0] ?? undefined
}
