'use client'

import { useEffect, useState } from 'react'

import { DeliveryDateData, getDeliveryDateInfo } from '@/api'
import { useStoreContext } from '@/providers'
import { ProductDetailStorageBadges } from './badges'
import { ObsoleteProductDetailStoragePrice } from './price'
import { STORE_CODES } from '@/common/utils'
import { Domain } from '@/common/types'

interface ProductDetailStorageProps {
  isConfigurableProduct: boolean
  isGiftCardProduct: boolean
  inStock: boolean
  longTermUnavailable: boolean
  small?: boolean
  isUnitPriceVisible?: boolean
}

export const ObsoleteProductDetailStorage = ({
  isConfigurableProduct,
  isGiftCardProduct,
  inStock,
  longTermUnavailable,
  small,
  isUnitPriceVisible,
}: ProductDetailStorageProps) => {
  const { storeCode } = useStoreContext()
  const [deliveryDateData, setDeliveryDateData] = useState<DeliveryDateData>()

  useEffect(() => {
    if (storeCode !== STORE_CODES[Domain.COM]) {
      getDeliveryDateInfo().then((response) => {
        setDeliveryDateData(response.data)
      })
    }
  }, [storeCode])

  return small ? (
    <div className="flex flex-col text-left">
      {isConfigurableProduct && !longTermUnavailable && (
        <ObsoleteProductDetailStoragePrice
          inStock={inStock}
          isUnitPriceVisible={isUnitPriceVisible}
          small
        />
      )}
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="flex order-2 md:order-1">
        <ProductDetailStorageBadges
          deliveryDateData={deliveryDateData}
          inStock={inStock}
          longTermUnavailable={longTermUnavailable}
          isGiftCardProduct={isGiftCardProduct}
        />
      </div>
      {isConfigurableProduct && !longTermUnavailable && (
        <div className="flex-col order-1 md:order-1 mb-5">
          <ObsoleteProductDetailStoragePrice
            deliveryDateData={deliveryDateData}
            inStock={inStock}
            isUnitPriceVisible={isUnitPriceVisible}
          />
        </div>
      )}
    </div>
  )
}
