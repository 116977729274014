'use client'
import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'

import { DeliveryDateData } from '@/api'
import { Currency } from '@/components/currency'
import { QuestionMarkRound } from '@/components/icons/svg'
import { Tooltip } from '@/components/tooltip'
import { useAuthContext } from '@/providers'
import { obsoloteGetPrice } from '@/utils'
import { DeliveryInfo } from './delivery-info'
import { selectors } from '@/common/constants/selectors-constants'
import { useProductDataContext } from '@/providers/product-data/product-data-context'
import { UnitPrice } from './unit-price'

type ProductDetailStoragePriceProps = {
  inStock: boolean
  deliveryDateData?: DeliveryDateData
  small?: boolean
  isUnitPriceVisible?: boolean
}

export function ObsoleteProductDetailStoragePrice({
  deliveryDateData,
  inStock,
  small,
  isUnitPriceVisible,
}: ProductDetailStoragePriceProps): JSX.Element | null {
  const t = useTranslations('Product')
  const { customerData } = useAuthContext()
  const { configurableProductVariant } = useProductDataContext()
  const {
    showRegularPrice,
    recommendedPrice,
    finalPrice,
    wholesaleDiscountPercentageFormatted,
    wholesaleDiscountPercentage,
  } = obsoloteGetPrice(configurableProductVariant?.product)
  const unitPrice = configurableProductVariant?.product?.unit_price

  const wholesaleDiscount = t.rich('detail.price.discount.wholesaleDiscount', {
    percent: () =>
      `${
        wholesaleDiscountPercentage ? '-' : ''
      }${wholesaleDiscountPercentageFormatted}`,
  })

  const isB2B = !!customerData?.is_b2b
  const isDeliveryInfoData =
    !isB2B && deliveryDateData && deliveryDateData.methods.length > 0
  if (inStock) {
    return (
      <div className="flex flex-col md:flex-row mt-4 justify-between items-start md:items-center">
        <div>
          <div data-test={selectors.PDP.productPrice}>
            <div className="flex gap-5 mb-2 relative w-[150px]">
              <Currency
                className={`text-secondary ${
                  small ? 'text-[15px]' : 'text-lg'
                } font-bold leading-3`}
                currency={finalPrice?.currency}
                price={finalPrice?.value}
              />
              {isB2B && (
                <div className="scale-[0.7] absolute right-0 -top-3">
                  <SaleRibbon />
                </div>
              )}
            </div>
            {/**
             * TODO: DPH will be later implemented in ticket 1577
             */}
            {/* <p className="text-secondary text-xs">s DPH</p> */}
          </div>
          {showRegularPrice && (
            <div className="text-grey-300 text-xs flex items-center">
              <Currency
                className="line-through"
                currency={recommendedPrice?.currency}
                price={recommendedPrice?.value}
              />
              <span className="cursor-help inline-flex ml-1">
                <Tooltip
                  direction="right"
                  text={
                    <span className="w-[210px] inline-block text-tooltip-text">
                      {isB2B
                        ? t('detail.price.discount.recommendedRetailPrice')
                        : t('detail.price.lowestLastPrice')}
                    </span>
                  }
                >
                  <QuestionMarkRound
                    className="fill-grey-300"
                    width={18}
                    height={18}
                  />
                </Tooltip>
              </span>
            </div>
          )}
          {isUnitPriceVisible && unitPrice && (
            <UnitPrice unitPrice={unitPrice} currency={finalPrice?.currency} />
          )}
        </div>
        <div>
          {isB2B && showRegularPrice && (
            <div className="py-2">{wholesaleDiscount}</div>
          )}
          {isDeliveryInfoData && (
            <div className="flex flex-col">
              <DeliveryInfo
                deliveryDateData={deliveryDateData}
                productPrice={finalPrice?.value}
              />
            </div>
          )}
        </div>
      </div>
    )
  } else if (finalPrice) {
    return (
      <div className="mt-2 price-estimated">
        <span className={twMerge('label', 'text-lg font-bold')}>
          {t('detail.price.expectedPrice')}&nbsp;
        </span>
        <Currency
          className="text-secondary text-lg font-bold"
          currency={finalPrice?.currency}
          price={finalPrice?.value}
        />
      </div>
    )
  } else {
    return null
  }
}

const SaleRibbon = () => {
  return (
    <div
      className={`flex items-center justify-center relative w-[65px] h-[38px] ml-[30px] p-[9px] bg-secondary text-lg text-[white] rounded-r  before:absolute before:content-[''] before:w-5 before:h-5 before:z-[-1] before:rotate-45 before:scale-150 before:-left-[8px]  before:bg-secondary before:rounded  font-bold`}
    >
      <span>-15%</span>
    </div>
  )
}
