'use client'
import React, { PropsWithChildren, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import { DeliveryDateData } from '@/api'
import { Flex } from '@/components/flex'

export interface BadgeDeliveryDateTooltipProps {
  methods: DeliveryDateData['methods']
}

export const BadgeDeliveryDateTooltip = ({
  methods,
  children,
}: PropsWithChildren<BadgeDeliveryDateTooltipProps>) => {
  const t = useTranslations('Product')
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className="relative"
      ref={wrapperRef}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {children}
      {isOpen && (
        <div
          className={twJoin(
            'bg-white z-10',
            'absolute left-0',
            'p-4 border border-grey-300 mt-1',
          )}
        >
          <Flex className="flex flex-col w-[210px] md:w-[250px] lg:w-[300px]">
            <h2 className="text-lg font-bold mb-1.5">
              {t('detail.badge.deliveryOptions')}
            </h2>
            <ul className="pl-4 list-disc">
              {methods.map(({ title, delivery_date: deliveryDate }) => (
                <li key={title} className="mb-1.5">
                  <span>{title}</span>{' '}
                  <strong suppressHydrationWarning className="text-success">
                    {deliveryDate}
                  </strong>
                </li>
              ))}
            </ul>
          </Flex>
        </div>
      )}
    </div>
  )
}
