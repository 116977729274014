import { Currency } from '@/common/types/price-types'
import { formatPrice } from '@/common/utils/price-utils'
import { useStoreContext } from '@/providers'

interface UnitPriceProps {
  unitPrice: {
    value: number
    unit: string
    // TODO: type string will be changed for enum when BE provides info
    currency?: string | null
  }
  /**
   * @deprecated will be removed as backend will subst. it with currency attribute in unitPrice
   */
  currency?: Currency | null
}

// TODO: this will be later refactored to use the new API (at the moment it is compatible with both old one and new one)

export const UnitPrice = ({ unitPrice, currency }: UnitPriceProps) => {
  const { storeCode, storeConfig } = useStoreContext()
  const unit = unitPrice?.unit?.split('/')[1]
  const unitePriceCurrency = unitPrice?.currency || currency
  const formattedUnitPrice = formatPrice({
    currency: unitePriceCurrency,
    storeCode,
    price: unitPrice.value,
  })
  const dualCurrency = {
    rate: storeConfig.checkoutCurrency?.rate,
    format: storeConfig.checkoutCurrency?.format,
  }

  const formattedDualPrice =
    dualCurrency?.rate && dualCurrency?.format
      ? formatPrice({
          storeCode,
          price: (unitPrice.value ?? 0) * dualCurrency.rate ?? 0,
          currencyFormat: dualCurrency.format,
          dualCurrency: true,
        })
      : undefined

  return (
    <div className="mb-1 text-xs">
      <span>
        {formattedUnitPrice}
        {dualCurrency?.format && formattedDualPrice && (
          <span className="text-grey-300 ml-1 font-normal">
            ~({formattedDualPrice})
          </span>
        )}
        /{unit || unitPrice.unit}
      </span>
    </div>
  )
}
